import { IBookingDetailsData } from "redux/application.types";
import axios from "./axios";

/**
 * Request the booking details from the backend if there is a bookingId passed.
 * @param url The backend route for details.
 * @param brandId The ID of the brand, if it is passed.
 * @returns The booking details if there is a brand ID, null otherwise.
 */
export const getBookingDetails = async (url: string, brandId: number | null) => {
    if (typeof brandId === "number") {
        const { data } = await axios.get<IBookingDetailsData>(url, { params: { brandId } });

        return data;
    }
    return null;
};
