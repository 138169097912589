import { Url } from "@edgetier/types";
import { AxiosRequestConfig } from "axios";
import urlJoin from "url-join";

import { IAction, ISettings, ISendEmailAction, IBookingDetailsData } from "redux/application.types";
import { IBlankEmail } from "redux/modules/email/email.types";
import { ISkill } from "redux/modules/setup/setup.types";

import axios from "./axios";
import formatProposedActions from "./format-proposed-actions";
import { doNothing } from "@edgetier/utilities";
import { getBookingDetails } from "./get-booking-details";

interface IAutomatableEmailPostData {
    readonly bookingId?: string | null;
    readonly chatId?: number;
    readonly emailId?: number;
    readonly interactionDetailId?: number | null;
    readonly languageId: number;
    readonly outboundEmailActionId?: number;
    readonly queryId?: number;
    readonly skillId?: number | null;
}

interface IAutomatableEmailResponseData {
    readonly actions: IAction[];
    readonly templateActions: IAction[];
    readonly wrapUpFormId: number;
    readonly skillId: number;
}

/**
 * Request the skill from the backend if there is a skillId present.
 * @param skillId The ID of the skill, if it is present.
 * @returns The skill if there is a skill ID, null otherwise.
 */
const getSkill = async (skillId: number | null | undefined) => {
    if (typeof skillId === "number") {
        const { data } = await axios.get<ISkill>(urlJoin(Url.Skills, String(skillId)));
        return data;
    }
    return null;
};

/**
 * Make the relevant requests needed to create a blank email and return email object with default properties.
 * @param postData      Data to be sent in the body of the POST request to the /automatable-email route.
 * @param configuration Axios configuration argument.
 */
const createBlankEmail = async (
    postData: IAutomatableEmailPostData,
    configuration?: AxiosRequestConfig
): Promise<IBlankEmail> => {
    const { languageId, bookingId } = postData;

    const [response, { data: settings }] = await Promise.all([
        axios.post<IAutomatableEmailResponseData>(Url.AutomatableEmail, postData, configuration),
        axios.get<ISettings>(Url.Settings),
    ]);

    const { actions, templateActions, wrapUpFormId, skillId } = response.data;

    const skill = await getSkill(postData.skillId ?? skillId);
    const { queryId, brandId, interactionDetailId } = (actions[0] as ISendEmailAction).data;
    let bookingDetailsData: IBookingDetailsData | null = null;

    // Don't update the booking details if a booking ID is passed
    if (typeof bookingId === "string" && bookingId.trim().length > 0) {
        doNothing();
    } else {
        // Request booking details based on the brand ID
        const url =
            typeof interactionDetailId === "number"
                ? urlJoin(Url.Interactions, interactionDetailId.toString(), Url.AgentInformation)
                : Url.BookingDetails;
        bookingDetailsData = await getBookingDetails(url, brandId);
    }

    return {
        actions: formatProposedActions(actions),
        bookingId: typeof bookingId === "undefined" ? null : bookingId,
        createdAt: new Date(),
        description: "New Email Details",
        emailId: null,
        handlingTimes: [],
        isExpired: false,
        isExpiring: false,
        isExpiringSoon: false,
        bookingDetails: bookingDetailsData?.bookingDetails,
        languageId,
        queryId,
        settings,
        skills: skill !== null ? [skill] : [],
        templateActions: formatProposedActions(templateActions),
        textTemplateVariables: bookingDetailsData?.textTemplateVariables,
        translatedToLanguageId: null,
        wrapUpFormDraft: null,
        wrapUpFormId,
    };
};

export default createBlankEmail;
